import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import clock from '../../assets/101_clock_icon.png';
import w3 from '../../assets/101_icon3.png';
import payment from '../../assets/102_card_types.png';
import {Helmet} from "react-helmet";


const Dryer = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Szolgáltatásaim</title>
            <meta name="description" content='Mosógépek, mosogatógép, szárítógépek, klímák nélkül ma már el sem tudjuk képzelni az életünket. Mint minden dolog, ami el tud romlani, egyszer sajnos ez is elromlik és ekkor
                                    szüksége
                                    lehet egy profi szerelőre.'/>
            <meta name='keywords'
                  content='mosogep, mosogatogep, klima, szarito, javitas, muszeres meres, szereles, elektronikus szamla, keszpenz, bankkartya'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8 z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> &gt; <a
                href="/szolgaltatasaim" className='link-primary'>Szolgáltatásaim</a> &gt; <a
                href="/szolgaltatasaim/szaritogep" className='link-primary'>Szárítógép</a></div>
        </div>
        <Block>
            <div className="flex flex-col gap-y-5 lg:mx-auto lg:w-fit">
                <div className="flex gap-x-5 items-start"><img src={clock} alt=""/>
                    <div className="flex flex-col">
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Munkaidő:</strong>
                        </p>
                            <p className=''>Hétfő-Péntek</p>
                            <p className='text-[20px]'>8:00-17:00</p></div>
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Telefonos ügyelet:
                            </strong>
                        </p>
                            <p className=''>
                                Hétfő-Szombat
                            </p>
                            <p className='text-[20px]'>
                                8:00-19:00
                            </p>
                        </div>
                    </div>

                </div>

                <div className=" flex flex-col w-full lg:grid lg:grid-cols-2 gap-5">
                    <a href="mailto:neptunusz.szerviz@gmail.com"
                       className="btn-primary w-full text-center ">Küldjön&nbsp;üzenetet</a> <a
                    href="tel:+36708010502"
                    className="btn-primary w-full text-center ">hívjon&nbsp;most</a>
                </div>
            </div>
        </Block>
        <Block>
            <div className="flex flex-col mx-auto w-fit">
                <article className={'text-left mx-auto'}>
                    <h3 className={'text-[40px] my-5 uppercase text-center lg:text-left'}
                        id={'szaritogep'}> SZÁRíTÓGÉP </h3>
                    <div className="grid md:grid-cols-4 lg:mx-16 gap-5 my-10">

                        <div className="">
                            <img
                                src={w3} alt="szárítógép" className='mx-auto'/></div>

                        <div className="md:col-span-3">
                            <p className='my-5'><strong>Szárítógép javítás, karbantartás, üzembe helyezés</strong></p>
                            <ul className='mb-5 list-disc list-outside ml-5  marker:text-brandPrimary'>
                                <li>A nyári szellőben szárított ruhák a legillatosabbak a világon. Bár tudjuk, hogy ez
                                    igaz mégsem lehet mindig igy kivitelezni. Az időjárás, a hely hiánya, és az idő
                                    hiány mind közbe szólhatnak. Egyre több otthonban jelen van már száritógép. Mint
                                    minden dolog, ami el tud romlani, egyszer sajnos ez is elromlik és ekkor szüksége
                                    lehet egy profi szárítógép szerelőre.
                                </li>
                                <li>A Neptunusz Szerviz ebben segít önnek, hétfőtől szombatig 8-19 óra között fogadom
                                    hívását és állok az Ön rendelkezésére.
                                </li>

                            </ul>
                            <p className='my-5'><strong></strong></p>Szárítógép karbantartás:
                            <ul className='mb-5 list-disc list-outside ml-5  marker:text-brandPrimary'>
                                <li>Száritógép használata során a mikro szemcsék az ép szűrőn is átjutva a gép
                                    belsejében lerakódnak ezáltal csökkentik a készülék hatásfokát, megnövelve az
                                    energia költségét és csökkentik a gép élettartamát. Ezért fontos a szárítógépek 2-3
                                    évenkénti karbantartása, amit a szerelő a gép megbontásával végez el, a kötelező
                                    felhasználási karbantartáson kívül. (Minden szárítás után a szűrőket ajánlatos
                                    tisztítani)

                                </li>
                                <li>A Neptunusz Szerviz ebben segít önnek, hétfőtől szombatig 8-19 óra között fogadom
                                    hívását és állok az Ön rendelkezésére.
                                </li>

                            </ul>
                            <p className='my-5'><strong>Szárítógép javítás, karbantartás, üzembe helyezés:</strong></p>
                            <ul className='mb-5 list-disc list-outside ml-5  marker:text-brandPrimary'>
                                <li>Telefonon felveszem az adatokat és a tapasztalt hibajelenséget.</li>
                                <li>Előre megbeszélt és leegyeztetett időpontban (idősávban) megérkezem a címre.</li>
                                <li>Szemrevételezéssel, műszeres méréssel megállapítom a készülék hibáját.</li>
                                <li>Tájékoztatom Önt a hibajelenségről, a hiba okáról, tanácsokkal látom el, majd adok
                                    egy
                                    árajánlatot.
                                </li>
                                <li>Amint az árajánlatot jóváhagyta, megkezdem a készülék szakszerű javítását, ügyelve a
                                    tisztaságra.
                                </li>
                                <li>A javítás végeztével üzempróbát végzek, fontos meggyőződni a javítás
                                    sikerességéről.
                                </li>
                                <li>Írok egy elektronikus számlát (csak magánszemély), amit elküldök az Ön email
                                    címére.
                                </li>
                                <li>A javítás költségét rendezheti:

                                    <p className='mx-3'>- készpénzzel</p>
                                    <p className='mx-3'>- bankkártyával</p>
                                </li>

                            </ul>
                            <img src={payment} alt="elfogadott fizetőeszközök"/>
                            <p className='my-5 font-bold text-[22px]'>Márkák melyeket javítok:</p>
                            <p className='mb-10 lg:w-3/4 text-center md:text-left'>AEG, ELECTROLUX, ZANUSSI, WHIRLPOOL,
                                INDESIT, ARISTON, SIEMENS,
                                BOSCH, GORENJE, CANDY, BEKO</p>
                            <p className='flex md:hidden items-center px-5 md:px-0'>
                                <a
                                    href="tel:+36708010502"
                                    className="btn-primary w-full text-center">hívjon&nbsp;most</a></p>
                            <p className={'hidden md:block'}>
                                Keressen bizalommal, a következő telefonszámon: +36708010502
                            </p>
                        </div>
                    </div>
                    <div
                        className="border-2 border-brandDark  px-12 md:px-48 lg:px-72 py-5 text-center font-normal w-fit mx-auto">
                        <strong>6
                            hónap
                            garancia
                        </strong> minden beépített
                        alkatrészre
                    </div>
                </article>
            </div>

        </Block>
        <Footer isMapVisible={true}/>
    </Layout>);
};

export default Dryer;
