import React, { useEffect } from 'react';
import Layout from "../../Components/Layout/Layout";
import classes from './home.module.scss';

import tick from '../../assets/101_check_mark.svg'
import blueTick from '../../assets/101_check_mark_b.png'
import w1 from '../../assets/101_icon1.png'
import w2 from '../../assets/101_icon2.png'
import w3 from '../../assets/101_icon3.png'
import w4 from '../../assets/101_icon4.png'
import balazs from '../../assets/101_picture-rolam.png'
import Card from "../../Components/Card/Card";
import Block from "../../Components/Block/Block";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from "react-helmet";

const Home = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (

        <Layout>
            <Helmet>
                <title>Neptunusz szerviz - javítás, beüzemelés, karbantartás</title>
                <meta name="description" content='Több mit 10 éves tapasztalatom van a háztartási gépek javításában. Célom, hogy a meghibásodott
                            készülékét a lehető legprecízebben tudjam megjavítani a gép elszállítása nélkül.'/>
                <meta name='keywords'
                    content='mosogep, mosogatogep, klima, szarito, javitas, karbantartas, beuzemeles, garancia, kiszallas, haztartasi gepek' />
                <meta name='robots' content='follow,index' />
            </Helmet>
            <div
                className={classes['hero'] + ' w-full h-[712px] mt-10 lg:mt-32 relative flex flex-col gap-4 md:gap-8 items-center justify-center z-10'}>
                <h1 className='text-white text-[50px] xl:text-[90px] text-center pt-16'>
                    Mosógép, mosogatógép <br />
                    és szárítógép szerviz
                </h1>
                <div className="flex flex-col md:flex-row gap-5 justify-center items-center">
                    <div className="flex"><img src={tick} alt="gyorsaság" />
                        <div className="text-white text-[20px] xl:text-[30px]">gyorsaság</div>
                    </div>
                    <div className="flex">
                        <img src={tick} alt="gyorsaság" />
                        <div className="text-white text-[20px] xl:text-[30px]">szakértelem</div>
                    </div>
                    <div className="flex"><img src={tick} alt="gyorsaság" />
                        <div className="text-white text-[20px] xl:text-[30px]">megbízhatóság</div>
                    </div>
                </div>
                <div className="btn-primary mb-44"><a href="tel:+36708010502">hivjon most</a></div>
                <div className="absolute bottom-0 translate-y-1/2">
                    <Card>
                        <div className="flex flex-col md:flex-row justify-between items-center gap-5">
                            <div className="text-baseText text-[32px] my-auto text-center"><strong>6 hónap
                                garancia </strong>minden
                                beépített
                                alkatrészre
                            </div>
                            <div className="w-fit">
                                <a
                                    href="/arak" className="btn-primary flex">árak&nbsp;megtekintése</a>
                            </div>

                        </div>
                    </Card>
                </div>
            </div>
            <Block color={'bg-baseGrey'}>
                <h3 className='text-3xl uppercase mt-32 mb-10 lg:my-10 text-center md:text-left'>Szolgáltatások</h3>
                <div className="grid md:grid-cols-2 xl:grid-cols-4 gap-y-5 md:gap-10 w-full justify-center mb-10">
                    <Card>
                        <div className="mx-auto flex items-center justify-center flex-col gap-y-5">
                            <img src={w1}
                                alt="mosógép javítás
                        , karbantartás üzembe helyezés" className='px-5' />
                            <div className="text-center">
                                mosógép javítás, karbantartás üzembe helyezés
                            </div>
                            <a href="/szolgaltatasaim/mosogep" className="link-primary">bővebb infó</a>
                        </div>

                    </Card><Card>
                        <div className="mx-auto flex items-center justify-center flex-col gap-y-5">
                            <img src={w2}
                                alt="mosogató javítás
                        , karbantartás üzembe helyezés" className='px-5' />
                            <div className="text-center">
                                mosogatógép
                                javítás,
                                karbantartás,
                                üzembe helyezés

                            </div>
                            <a href="/szolgaltatasaim/mosogatogep" className="link-primary">bővebb infó</a>
                        </div>
                    </Card><Card>
                        <div className="mx-auto flex items-center justify-center flex-col gap-y-5">
                            <img src={w3}
                                alt="szárítógép javítás,
karbantartás,
üzembe helyezés
" className='px-5' />
                            <div className="text-center">
                                szárítógép javítás,
                                karbantartás,
                                üzembe helyezés

                            </div>
                            <a href="/szolgaltatasaim/szaritogep" className="link-primary">bővebb infó</a>
                        </div>
                    </Card><Card>
                        <div className="mx-auto flex h-full items-center justify-between flex-col gap-y-5">
                            <img src={w4}
                                alt="klíma tisztítás
" className='px-5 my-auto' />
                            <div className="text-center">
                                klíma tisztítás

                            </div>
                            <a href="/szolgaltatasaim/klima" className="link-primary">bővebb infó</a>
                        </div>
                    </Card>
                </div>

            </Block>
            <Block color={'bg-white'}>
                <h3 className='text-3xl uppercase mt-5 mb-5 text-center md:text-left lg:pl-40'>Rólam</h3>
                <div className="grid lg:grid-cols-2 gap-y-5 md:gap-10 w-full">
                    <article className="flex flex-col h-full lg:pl-40 order-2 lg:order-1">
                        <p><strong>Mándoki Balázs</strong></p>
                        <p className={'mb-10'}>szervizvezető</p>
                        <p className={'mb-5'}>Ars poetikám: a minél több elégedett ügyfél.</p>

                        <p className='mb-5'>
                            Több mit 15 éves tapasztalatom van a háztartási gépek javításában. Szinte az összes
                            típust
                            ismerem a műhelyben töltött hosszú éveknek köszönhetően. Célom, hogy a meghibásodott
                            készülékét az Ön
                            otthonában, a lehető legprecízebben tudjam megjavítani a gép elszállítása nélkül.</p>

                        <p className='flex  gap-x-2 mb-3'><img src={blueTick} alt="" /> gyorsaság </p>
                        <p className='flex  gap-x-2 mb-3'><img src={blueTick} alt="" /> szakértelem </p>
                        <p className='flex  gap-x-2 mb-3'><img src={blueTick} alt="" /> megbízhatóság</p>

                    </article>
                    <div className="flex justify-center order-1 lg:order-2">
                        <img src={balazs} alt="Mándoki Balázs" className='rounded-md' />
                    </div>

                </div>
            </Block>

            <section
                className='bg-gradient-to-r from-[#00A099] to-[#1D70B7] flex flex-col items-center py-12 md:py-20 text-white'>
                <h3 className='text-[40px] mb-5 uppercase'>Árak</h3>
                <p className='text-center mb-5 px-5'>Kiszállási területtől függetlenül egységes bruttó árak. </p>

                <p className='text-center mb-10 px-5'>Számlát csak magánszemélyek részére tudok kiállítani.</p>

                <p className='text-center text-[26px] mb-5 px-5'>A szervizelés költsége az alábbi tételekből tevődik össze:</p>

                <div className='grid md:grid-cols-4 gap-2 mb-5 px-5 text-center'>

                    <div className='flex flex-col items-center gap-2 mb-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                        </svg>
                        Hiba bevizsgálás
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 016.775-5.025.75.75 0 01.313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 011.248.313 5.25 5.25 0 01-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 112.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0112 6.75zM4.117 19.125a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clipRule="evenodd" />
                        </svg>
                        Javítás
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                        </svg>
                        Alkatrész
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
                            <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
                            <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                        </svg>
                        Parkolási díj a parkolási övezetekben
                    </div>
                </div>
                
                <p className='text-center mb-10 px-5 uppercase text-lg lg:text-2xl font-bold'>Javítás esetén nincs hiba bevizsgálási díj!</p>

                <p className='text-center mb-5 md:mb-10 px-5 text-[26px] uppercase'><span
                    className='text-baseText font-bold'>6 hónap garancia</span> minden
                    beépített alkatrészre.</p>
                <div className="btn-secondary"><a href="/arak">Árak megtekintése</a></div>
            </section>
            <Footer isExtended={true} isMapVisible={true} />
        </Layout>


    )
        ;
};

export default Home;
