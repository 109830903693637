export const brands = [
    {
        brand: 'samsung', image: '101_logo_samsung.png'
    }, {
        brand: 'bauknecht', image: '101_logo_bauknecht.png'
    }, {
        brand: 'siemens', image: '101_logo_siemens.png'
    }, {
        brand: 'aeg', image: '101_logo_aeg.png'
    }, {
        brand: 'zanussi', image: '101_logo_zanussi.png'
    }, {
        brand: 'whirlpool', image: '101_logo_whirlpool.png'
    }, {
        brand: 'gorenje', image: '101_logo_gorenje.png'
    }, {
        brand: 'electrolux', image: '101_logo_electrolux.png'
    }, {
        brand: 'fagor', image: '101_logo_fagor.png'
    }, {
        brand: 'ariston', image: '101_logo_ariston.png'
    }, {
        brand: 'ikea', image: '101_logo_ikea.png'
    }, {
        brand: 'lg', image: '101_logo_lg.png'
    }, {
        brand: 'beko', image: '101_logo_beko.png'
    }, {
        brand: 'indesit', image: '101_logo_indesit.png'
    }, {
        brand: 'candy', image: '101_logo_candy.png'
    }, {
        brand: 'bosch', image: '101_logo_bosch.png'
    }, {
        brand: 'amica', image: 'amica_101.png'
    }, {
        brand: 'ok.', image: 'ok_101.png'
    }, {
        brand: 'haier', image: 'haier_101.png'
    }, {
        brand: 'hausmeister', image: 'hausmeister_101.png'
    }
]
