import { useEffect } from "react";
import {Routes, Route} from "react-router-dom";
import './App.css';
import Home from "./pages/Home/Home";
import Contact from "./pages/Contact/Contact";
import Advices from "./pages/Advices/Advices";
import Works from "./pages/Works/Works";
import Prices from "./pages/Prices/Prices";
import Services from "./pages/Services/Services";
import NotFound from "./pages/404/NotFound";
import Clime from "./pages/Services/Clime";
import DishWasher from "./pages/Services/DishWasher";
import WashingMachine from "./pages/Services/WashingMachine";
import Dryer from "./pages/Services/Dryer";
import { analytics } from "./analitics/analitics";

import 'tw-elements';

const App = () => {
    useEffect(() => {
        analytics(window, document, 'script', 'dataLayer', 'GTM-KRBMX8M');
    },[])
    
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/kapcsolat" element={<Contact/>}/>
            <Route path="/hasznos-tanacsok" element={<Advices/>}/>
            <Route path="/munkaim" element={<Works/>}/>
            <Route path="/arak" element={<Prices/>}/>
            <Route path="/szolgaltatasaim" element={<Services/>}/>
            <Route path="/szolgaltatasaim/klima" element={<Clime/>}/>
            <Route path="/szolgaltatasaim/mosogatogep" element={<DishWasher/>}/>
            <Route path="/szolgaltatasaim/mosogep" element={<WashingMachine/>}/>
            <Route path="/szolgaltatasaim/szaritogep" element={<Dryer/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    )
}

export default App;
