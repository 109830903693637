import React, {useEffect} from 'react';
import Layout from "../../Components/Layout/Layout";
import Footer from "../../Components/Footer/Footer";
import Block from "../../Components/Block/Block";
import clock from '../../assets/101_clock_icon.png';

import {prices} from '../../store/prices'
import {Helmet} from "react-helmet";

const Prices = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const getPrices = () => {
        return prices.map(category => <div key={category.id} className='my-10'>
            <h4 className='text-center font-bold mb-5 uppercase text-[30px]'>{category.categoryName}</h4>
            {category.categoryName === 'Hiba bevizsgálás' &&    
                <p className='text-center mb-10 px-5 uppercase text-xl font-bold'>Javítás esetén nincs hiba bevizsgálási díj!</p>
            }
            {category.categoryItems.map(item => <div
                className='grid grid-cols-2 items-center py-2 even:bg-baseGrey lg:w-2/3 mx-auto text-[16px] gap-1 md:text-[24px]'
                key={category.categoryName + item.id}>
                <div className={'px-3'}>{item.item}</div>
                <div className="text-center">{item.price}</div>
            </div>)}
        </div>)
    }
    return (<Layout>
        <Helmet>
            <title>Neptunusz szerviz - Árak</title>
            <meta name="description"
                  content='Kiszállási területtől függetlenül egységes bruttó árak. Javítás esetén nincs hiba bevizsgálási díj, csak az árajánlatban szereplő javítási költség.'/>
            <meta name='keywords'
                  content='brutto arak, bevizsgalas, arajanlat, arak, javitas, szereles, beuzemeles, karbantartas, tisztitas'/>
            <meta name='robots' content='follow,index'/>
        </Helmet>
        <div
            className={' w-full  mt-10 lg:mt-32 flex-col gap-4 md:gap-8 z-10'}>
            <div className=" pt-20 md:pt-28 container mx-auto px-5"><a href="/"
                                                                       className='link-primary text-black'>Kezdőlap</a> &gt; <a
                href="/arak" className='link-primary'>Árak</a></div>
        </div>
        <Block>
            <div className="flex flex-col gap-y-5 lg:mx-auto lg:w-fit">
                <div className="flex gap-x-5 items-start"><img src={clock} alt=""/>
                    <div className="flex flex-col">
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Munkaidő:</strong>
                        </p>
                            <p className=''>Hétfő-Péntek</p>
                            <p className='text-[20px]'>8:00-17:00</p></div>
                        <div className="flex flex-col md:flex-row md:gap-x-3 md:items-center text-[16px]"><p>
                            <strong>Telefonos ügyelet:
                            </strong>
                        </p>
                            <p className=''>
                                Hétfő-Szombat
                            </p>
                            <p className='text-[20px]'>
                                8:00-19:00
                            </p>
                        </div>
                    </div>

                </div>

                <div className=" flex flex-col w-full lg:grid lg:grid-cols-2 gap-5">
                    <a href="mailto:neptunusz.szerviz@gmail.com"
                       className="btn-primary w-full text-center ">Küldjön&nbsp;üzenetet</a> <a
                    href="tel:+36708010502"
                    className="btn-primary w-full text-center ">hívjon&nbsp;most</a>
                </div>
            </div>
        </Block>
        <Block color={'bg-baseGrey'}>
            <div className="flex flex-col mx-auto w-fit">
                <h1 className={' text-[40px] my-5 uppercase text-center'}> Árak </h1>
                <article className={'font-bold text-left'}>
                <p className='text-center mb-5 px-5'>Kiszállási területtől függetlenül egységes bruttó árak. </p>

                <p className='text-center mb-10 px-5'>Számlát csak magánszemélyek részére tudok kiállítani.</p>

                <p className='text-center text-[26px] mb-5 px-5'>A szervizelés költsége az alábbi tételekből tevődik össze:</p>

                <div className='grid md:grid-cols-4 gap-2 mb-5 px-5 text-center'>
                    

                <div className='flex flex-col items-center gap-2 mb-5'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z" clipRule="evenodd" />
                        </svg>
                        Hiba bevizsgálás
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M12 6.75a5.25 5.25 0 016.775-5.025.75.75 0 01.313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 011.248.313 5.25 5.25 0 01-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 112.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0112 6.75zM4.117 19.125a.75.75 0 01.75-.75h.008a.75.75 0 01.75.75v.008a.75.75 0 01-.75.75h-.008a.75.75 0 01-.75-.75v-.008z" clipRule="evenodd" />
                        </svg>
                        Javítás
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                        </svg>
                        Alkatrész
                    </div>

                    <div className='flex flex-col items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path d="M3.375 4.5C2.339 4.5 1.5 5.34 1.5 6.375V13.5h12V6.375c0-1.036-.84-1.875-1.875-1.875h-8.25zM13.5 15h-12v2.625c0 1.035.84 1.875 1.875 1.875h.375a3 3 0 116 0h3a.75.75 0 00.75-.75V15z" />
                            <path d="M8.25 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM15.75 6.75a.75.75 0 00-.75.75v11.25c0 .087.015.17.042.248a3 3 0 015.958.464c.853-.175 1.522-.935 1.464-1.883a18.659 18.659 0 00-3.732-10.104 1.837 1.837 0 00-1.47-.725H15.75z" />
                            <path d="M19.5 19.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z" />
                        </svg>
                        Parkolási díj a parkolási övezetekben
                    </div>
                </div>

                <p className='text-center mb-10 px-5 uppercase text-lg lg:text-2xl font-bold'>Javítás esetén nincs hiba bevizsgálási díj!</p>

                <div className='flex justify-center'>
                    <div className="border-2 border-brandDark w-fit px-10 py-5 text-center font-normal">
                        <strong>6 hónap garancia </strong>
                            minden beépített alkatrészre
                    </div>
                </div>
            
                </article>
            </div>

        </Block>
        <Block>
            {getPrices()}
        </Block>
        <Footer isMapVisible={true}/>
    </Layout>);
};

export default Prices;
